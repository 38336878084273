import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import WhyI2v from "./pages/WhyI2v";
import FirstProduct from "./pages/FirstProduct";
import SecondProduct from "./pages/SecondProduct";
import TechnologyPartner from "./pages/TechnologyPartner";
import ChannelPartner from "./pages/ChannelPartner";
import News from "./pages/News";
import CameraBrand from "./pages/CameraBrand";
import ContactUs from "./pages/ContactUs";
import Faq from "./pages/Faq";
import CarrearPages from "./pages/CarrearPages";
import Troubleshoot from "./pages/Troubleshoot";
import ScrollToTop from "./hooks/ScrollToTop";
import ThirdProduct from "./pages/ThirdProduct";
import FourthProduct from "./pages/FourthProduct";
import FifthProduct from "./pages/FifthProduct";
import SixProduct from "./pages/SixProduct";
import SingleJobDetail from "./pages/SingleJobDetail";
import SeventhProduct from "./pages/SeventhProduct";
import VideoBasedFiredetection from "./pages/aiBasedVideoAnalytics/VideoBasedFiredetection";
import AbandonedObjectDetection from "./pages/aiBasedVideoAnalytics/AbandonedObjectDetection";
import ImageEnhancement from "./pages/aiBasedVideoAnalytics/ImageEnhancement";
import BoundaryLoitheringDetection from "./pages/aiBasedVideoAnalytics/BoundaryLoitheringDetection";
import IndoorPeopleCounting from "./pages/aiBasedVideoAnalytics/IndoorPeopleCounting";
import ObjectRemovalDetection from "./pages/aiBasedVideoAnalytics/ObjectRemovalDetection";
import VideoPerimeterTripwire from "./pages/aiBasedVideoAnalytics/VideoPerimeterTripwire";
import StoppedVehicleDetection from "./pages/aiBasedVideoAnalytics/StoppedVehicleDetection";
import CameraTamperingDetection from "./pages/aiBasedVideoAnalytics/CameraTamperingDetection";
import PerimeterIntrusionDetection from "./pages/aiBasedVideoAnalytics/PerimeterIntrusionDetection";
import WrongWayDetection from "./pages/aiBasedVideoAnalytics/WrongWayDetection";
import CrowdCountingDetection from "./pages/aiBasedVideoAnalytics/CrowdCountingDetection";
import NoHelmetDetection from "./pages/aiBasedVideoAnalytics/NoHelmetDetection";
import ArmedPersonDetection from "./pages/aiBasedVideoAnalytics/ArmedPersonDetection";
import QueueAnalysisLengthDetection from "./pages/aiBasedVideoAnalytics/QueueAnalysisLengthDetection";
import HumanPatternRecognition from "./pages/aiBasedVideoAnalytics/HumanPatternRecognition";
import Anpr from "./pages/aiBasedVideoAnalytics/Anpr";
import SpeedDetection from "./pages/aiBasedVideoAnalytics/SpeedDetection";
import NoPPEDetection from "./pages/aiBasedVideoAnalytics/NoPPEDetection";
import AutomaticNumberPlateRecognition from "./pages/IntelligentTrafficSolutions/AutomaticNumberPlateRecognition";
import AutomaticTrafficCountingClassification from "./pages/IntelligentTrafficSolutions/AutomaticTrafficCountingClassification";
import VideoIncidentDetectionSystem from "./pages/IntelligentTrafficSolutions/VideoIncidentDetectionSystem";
import Violation from "./pages/IntelligentTrafficSolutions/Violation";
import WhyToChoose from "./pages/WhyToChoose";
import ComputerVisonEngineer from "./pages/career/ComputerVisonEngineer";
import FullStackDeveloper from "./pages/career/FullStackDeveloper";
import SoftwareDeveloper from "./pages/career/SoftwareDeveloper";
import RegionalSalesManager from "./pages/career/RegionalSalesManager";
import TechnicalSalesEngineer from "./pages/career/TechnicalSalesEngineer";
import SoftwareSupportProfessional from "./pages/career/SoftwareSupportProfessional";
import QualityAssuranceProfessional from "./pages/career/QualityAssuranceProfessional";
import DebrisGarbageDetection from "./pages/aiBasedVideoAnalytics/DebrisGarbageDetection";
import SuccessStories from "./pages/SuccessStories";
import Certification from "./pages/Certification";
import Resources from "./pages/Resources";

function App() {
  return (
    <div className="App">
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/whyi2v" element={<WhyI2v />} />
          <Route path="/success-stories" element={<SuccessStories/>} />
          <Route path="/whytochoose" element={<WhyToChoose />} />
          <Route path="/certification" element={<Certification />} />


          <Route path="/ip-Video" element={<FirstProduct />} />
          <Route path="/ai-Based-Video" element={<SecondProduct />} />
          <Route path="/video-based-firedetection" element={<VideoBasedFiredetection />} />
          <Route path="/abandoned-object-detection" element={<AbandonedObjectDetection />} />
          <Route path="/perimeter-intrusion-detection" element={<PerimeterIntrusionDetection />} />
          <Route path="wrong-way-detection" element={<WrongWayDetection />} />
          <Route path="/boundary-loitering-detection" element={<BoundaryLoitheringDetection />} />
          <Route path="/people-counting" element={<IndoorPeopleCounting />} />
          <Route path="/image-enhancement" element={<ImageEnhancement />} />
          <Route path="/object-removal-detection" element={<ObjectRemovalDetection />} />
          <Route path="/video-perimeter-tripware" element={<VideoPerimeterTripwire />} />
          <Route path="/stopped-vehicle-detection" element={<StoppedVehicleDetection />} />
          <Route path="/camera-tampring-detection" element={<CameraTamperingDetection />} />
          <Route path="/crowd-counting-detection" element={<CrowdCountingDetection />} />
          <Route path="/no-helmet-detection" element={<NoHelmetDetection />} />
          <Route path="/armed-person-detection" element={<ArmedPersonDetection />} />
          <Route path="/queue-analysis-length-detection" element={<QueueAnalysisLengthDetection />} />
          <Route path="/human-pattern-recognition" element={<HumanPatternRecognition />} />
          <Route path="/anpr" element={<Anpr />} />
          <Route path="/speed-detection" element={<SpeedDetection />} />
          <Route path="/no-ppe-kit-detection" element={<NoPPEDetection />} />
          <Route path="/debris-garbage-detection" element={<DebrisGarbageDetection />} />

          <Route path="/intelligent-traffic" element={<ThirdProduct />} />
          <Route path="/automatic-number-plate-recognition" element={<AutomaticNumberPlateRecognition />} />
          <Route path="/automatic-traffic-counting-classification" element={<AutomaticTrafficCountingClassification />} />
          <Route path="/video-incident-detection-system" element={<VideoIncidentDetectionSystem />} />
          <Route path="/violation" element={<Violation />} />

          <Route path="/central-monitoring" element={<FourthProduct />} />
          <Route path="/cloud-surveillance" element={<FifthProduct />} />
          <Route path="/integrated-command" element={<SixProduct />} />
          <Route path="/face-Recognition" element={<SeventhProduct />} />
          <Route path="/technology-partner" element={<TechnologyPartner />} />
          <Route path="/channel-partner" element={<ChannelPartner />} />
          <Route path="/news" element={<News />} />
          <Route path="/camera-brand" element={<CameraBrand />} />
          <Route path="/download" element={<Resources />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/career" element={<CarrearPages />} />
          <Route path="/computer-vision-engineer" element={<ComputerVisonEngineer />} />
          <Route path="/fullstack-developer" element={<FullStackDeveloper />} />
          <Route path="/software-developer" element={<SoftwareDeveloper />} />
          <Route path="/regionalsales-manager" element={<RegionalSalesManager />} />
          <Route path="/technical-sales-engineer" element={<TechnicalSalesEngineer />} />
          <Route path="/Software-Support-Professional" element={<SoftwareSupportProfessional />} />
          <Route path="/Quality-Assurance-Professional" element={<QualityAssuranceProfessional />} />

          <Route path="/job-detail" element={<SingleJobDetail />} />
          <Route path="/troubleshoot" element={<Troubleshoot />} />
        </Routes>
      </ScrollToTop>
    </div>
  );
}

export default App;

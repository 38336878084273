import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { ImFacebook } from "react-icons/im";
import { BsTwitter,BsLinkedin,BsYoutube } from "react-icons/bs";
import { useInView } from "framer-motion";
import { useTranslation } from "react-i18next";
const Footer = () => {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const {t} =useTranslation();
  return (
    <>
      <footer
        className="footerclass"
        // style={{ backgroundImage: "url('/assets/images/banner3.png')" }}
        style={{
          backgroundImage: "url('/assets/images/footerimg.jpg')",
        }}
      >
        <div className="flex justify-center items-center gap-8 w-full">
          
          <a href="https://www.facebook.com/pages/i2V-Systems/195083397196058" className="inline-block text-primary p-3 rounded-full cursor-pointer bg-white z-10 hover:scale-105 hover:text-black duration-500"
            ref={ref}
            style={{
              transform: isInView ? "none" : "translateY(-100px)",
              opacity: isInView ? 1 : 0,
              transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
            }}
          >
            <ImFacebook />
          </a>
          <a href="http://www.linkedin.com/company/2182829" className="inline-block text-primary p-3 rounded-full cursor-pointer  bg-white  z-10 hover:scale-105 hover:text-black duration-500"
            ref={ref}
            style={{
              transform: isInView ? "none" : "translateY(100px)",
              opacity: isInView ? 1 : 0,
              transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
            }}
          >
            <BsLinkedin />
          </a>
          <a href="https://twitter.com/i2vsys" className="inline-block text-primary p-3 rounded-full cursor-pointer  bg-white  z-10 hover:scale-105 hover:text-black duration-500"
            ref={ref}
            style={{
              transform: isInView ? "none" : "translateY(-100px)",
              opacity: isInView ? 1 : 0,
              transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
            }}
          >
            <BsTwitter />
          </a>
          <a href="https://www.youtube.com/channel/UCeKmeHXQ2N0w6hTsi_BiOSQ/featured" className="inline-block text-primary p-3 rounded-full cursor-pointer  bg-white  z-10 hover:scale-105 hover:text-black duration-500"
            ref={ref}
            style={{
              transform: isInView ? "none" : "translateY(100px)",
              opacity: isInView ? 1 : 0,
              transition: "all 0.5s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
            }}
          >
            <BsYoutube />
          </a>
        </div>
        <div className="flex flex-col justify-center items-center gap-4 w-full pt-5">
          <div className="flex  justify-center items-center gap-4">
            <div className="text-sm text-white text-semibold textshadow  hover:scale-105 duration-500 z-10 cursor-pointer ">
              {t("Terms of use")} |{" "}
            </div>
            <div className="text-sm text-white text-semibold  textshadow hover:scale-105 duration-500 z-10 cursor-pointer ">
              {t("Privacy Policy")} |{" "}
            </div>

            <div className="text-sm text-white  text-semibold  textshadow hover:scale-105 duration-500 z-10 cursor-pointer ">
              <Link to="#"> {t("Careers")} </Link>
            </div>

            {/* <div className="text-sm text-white text-semibold  textshadow hover:scale-105 duration-500 z-10 cursor-pointer ">
                            <Link to="#">  Careers </Link>

                        </div> */}
          </div>

          <footer className="  bg-[#EA1D2480] text-center w-full text-sm  py-5 lg:mt-10">
            <span className="text-white drop-shadow-2xl lg:px-0 px-5 mx-auto">
              {" "}
              {t("Copyright © 2022 All Rights Reserved - i2V Systems Pvt. Ltd.")}
            </span>
          </footer>
        </div>
      </footer>
    </>
  );
};

export default Footer;
